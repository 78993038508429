import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { x } from '@xstyled/styled-components'

const AgeVerificationPopUp = (props) => {
  const [year, setYear] = useState('')
  const [message, setMessage] = useState('')
  const history = useHistory()

  const handleInputChange = (e) => {
    setYear(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const currentYear = new Date().getFullYear()
    const age = currentYear - year
    if (age < 18) {
      setMessage('You must be at least 18 years old to enter the website.')
      setTimeout(() => {
        history.push('/')
      }, 3000)
    } else {
      props.onVerify()
    }
  }

  return (
    <x.div position='relative' mx='auto' display='flex' w={{ sm: '100%', lg: '40%' }} zIndex={2} flexDirection='column' alignItems='flex-center' justifyContent='center' p={20} bg='rgba( 255, 255, 255, 0.25 )' borderRadius={10} border='1px solid rgba( 255, 255, 255, 0.18 )' boxShadow='0 8px 32px 0 rgba( 31, 38, 135, 0.37 )'>
      <x.h1>Enter the World of Sports Betting</x.h1>
      <x.p>Join us for an exciting sports betting experience. Please confirm your age to start placing bets.</x.p>
      <x.form onSubmit={handleSubmit}>
        <x.div display='flex' flexDirection={{ sm: 'column', lg: 'row' }} m={10}>
          <x.input
            borderRadius={5}
            textAlign='center'
            bg='rgba( 255, 255, 255, 0.25 )'
            border='2px solid grey'
            type='number'
            placeholder='YYYY'
            value={year}
            onChange={handleInputChange}
            required
            flex='1 1 auto'
            h={10}
            w='100%'
            mb={{ sm: 10, lg: 0 }}
            mr={5}
          />
          <x.button ml={{ sm: 0, lg: 5 }} w='100%' backgroundColor={{ _: '#b41400', hover: '#333' }} color='white' border='none' padding='10px 20px' borderRadius={5} fontSize={18} cursor='pointer' transition='background-color 0.3s ease' type='submit' flex='1 1 auto'>Enter Section</x.button>
        </x.div>
      </x.form>
      {message && <x.p p={10} m={10} fontWeight='bold' H color='red'>{message}</x.p>}
      <x.p>
        By verifying your age, you ensure legal compliance and gain access to our sports betting platform. Access for individuals under the legal age is not permitted.
      </x.p>
    </x.div>

  )
}

export default AgeVerificationPopUp
