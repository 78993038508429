import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { x } from '@xstyled/styled-components'
// import useHasMounted from '../../lib/useHasMounted'

import Articles from '../components/Articles'
import { fetchArticles, fetchMoreArticles, setSectionTitle } from '../../store/articles'
import { sectionPageView, setPageType } from '../../store/app'
import { getVisitorId, getSessionId } from '../../store/article'
import WingBanners from '../components/WingBanners'
import Ad from '../components/Ad'
import { FORMATS, MAPPINGS } from '../components/AdFormats'
import NoImage from '../../../assets/images/general/no-image.png'
import { useCookie } from '../components/ui/utils'

import { TvBoxSkeleton, MostReadWidgetSkeleton, ArticleDeckSkeleton, ThreeArticleDeckSkeleton, SixArticleDeckSkeleton } from '../components/Skeletons'
import HomePageTVBox from '../../templates/HomePageTVBox'
import MostReadWidget from '../components/MostReadWidget'
import SecondaryNav from '../components/SecondaryNav'
import AgeVerificationPopUp from '../components/AgeVerificationPopUp'
import TrendsBar from '../components/TrendsBar'
import ArticleDeck from '../../templates/ArticleDeck'
import ThreeArticleDeck from '../../templates/ThreeArticleDeck'
import SixArticleDeck from '../../templates/SixArticleDeck'
import BasicTemplate from '../../templates/Default'
import NotFound from './NotFound'
import OtherPublications from '../components/OtherPublications'
// import Membrana from '../components/Membrana'

const Leaderboard = [
  [[1024, 0], [[728, 90], [970, 90], [970, 250], 'fluid']],
  [[640, 0], [[460, 60], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], 'fluid']]
]

const HP = [
  [[1024, 0], [[300, 600], 'fluid']],
  [[640, 0], [[300, 600], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], 'fluid']]
]

const Wing = [
  [[1024, 0], [[160, 600], [120, 600]]],
  [[640, 0], []],
  [[0, 0], []]
]

const mpuRHS = [
  [[1024, 0], [[300, 250], 'fluid']],
  [[640, 0], [[300, 250], [336, 280], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], 'fluid']]
]

export const Skeleton = (props) => {
  return (
    <>
      <x.div w='100%' maxWidth={1220} mx='auto' display='grid' gridTemplateColumns={{ sm: '100%', lg: 'minmax(min-content, 560px) minmax(min-content, 282px) 300px' }} gap={{ lg: '40' }} mt={50} mb={30}>
        <TvBoxSkeleton />
        <x.aside>
          <MostReadWidgetSkeleton />
          <x.div textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column' p={{ sm: '20px', lg: '0' }}>
            <x.div w={300} h={250} bg='#D3D3D340' />
          </x.div>
        </x.aside>
      </x.div>
      <TrendsBar />
      <x.div my={50} mx={-20} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
        <x.div w={970} h={250} bg='#D3D3D340' />
      </x.div>
      <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
        <SixArticleDeckSkeleton />
        <ArticleDeckSkeleton />
        <ThreeArticleDeckSkeleton />
      </x.div>
    </>
  )
}

const SportsBettingPage = (props) => {
  const adPath = `${props.adPath || (props.location.pathname + '/homepage')}`
  setPageType('section')

  const [isVerified, setIsVerified] = useState(false)
  const [ageVerificationCookie, setAgeVerificationCookie] = useCookie('AgeVerificationCookie', 'none')

  useEffect(
    () => {
      if (('/' + props.section) === props.location.pathname) {
        props.sectionPageView(props.location.pathname, props.section, props.visitorId, props.sessionId, props.app.firstRender)
      }
      props.setSectionTitle('')
      const path = `/${process.env.RAZZLE_DFP_CODE}${props.adPath || (props.location.pathname === '/' ? '/homepage' : props.location.pathname)}`
      const descriptionUrl = `description_url=${encodeURIComponent(window.location.href)}`
      const custParams = 'cust_params=title%3d{mediainfo.name}%26description%3d{mediainfo.description}%26keywords%3d{mediainfo.tags}'
      window.oovvuuReplacementAdServerURL = `https://pubads.g.doubleclick.net/gampad/ads?iu=${path}&sz=640x480&${custParams}&ciu_szs=300x250&gdfp_req=1&ad_rule=1&ad_type=video&output=vmap&unviewed_position_start=1&env=vp&impl=s&correlator=&${descriptionUrl}&vpmute=1&plcmt=1`
    },
    [props.section]
  )

  useEffect(() => {
    if (isVerified && ageVerificationCookie === 'none') {
      setAgeVerificationCookie('verified', { days: 30 })
    }
  }, [isVerified, ageVerificationCookie])

  return (
    <>
      {(!isVerified && ageVerificationCookie === 'none') &&
        <x.div maxWidth={{ sm: '1260px', wAdD: props.app.wallpaperActive ? '1220px' : '1660px' }} w='100%' bg='#ffffffcc' position='absolute' top={0} bottom={0} left={0} right={0} zIndex={1} />}
      {(!isVerified && ageVerificationCookie === 'none') &&
        <x.div h='120vh' mt={{ sm: '150px', lg: props.app.wallpaperActive ? '0px' : '200px' }}>
          <AgeVerificationPopUp onVerify={() => setIsVerified(true)} />
        </x.div>}
      {(isVerified || ageVerificationCookie === 'verified') &&
        <>
          <Helmet
            titleTemplate='%s'
            title={props.title}
          >
            <meta property='og:title' content={props.title} />
            <meta itemProp='headline' content={props.title} />
            <meta name='description' content={props.description} />
            <meta itemProp='description' content={props.description} />
            <meta property='og:description' content={props.description} />
            <meta property='og:url' content={props.url} />
            <meta property='keywords' content={props.keywords} />
            <meta name='twitter:site' content={props.twitterName} />
            <meta name='twitter:creator' content={props.twitterName} />
            <meta name='twitter:title' content={props.title} />
            <meta name='twitter:description' content={props.description} />
            {/* {process.env.RAZZLE_ENABLE_MEBRANA && <script async='async' src='https://cdn.membrana.media/iol/ym.js' />} */}
          </Helmet>
          <x.main px={{ sm: '20px', wAdD: props.app.wallpaperActive ? '0px' : '200px' }} mt={{ sm: '96px', lg: props.app.wallpaperActive ? '0px' : '173px' }} w='100%' maxWidth={{ sm: '1260px', wAdD: props.app.wallpaperActive ? '1220px' : '1660px' }} mx='auto'>
            <Ad isConnected={props.isConnected} adPath={props.adPath} slotId='interstitial' targeting={{ interstitial: 'interstitial' }} collapseEmptyDiv className='advert' anchor='interstitial' mapping={MAPPINGS.Interstitial} {...FORMATS.interstitial} />
            {/* <Ad isConnected={props.isConnected} slotId='third-party' targeting={{ Pos: 'Third-Party' }} collapseEmptyDiv className='advert' mapping={mapping1} {...FORMATS.thirdParty} /> */}
            <WingBanners isConnected={props.isConnected} adPath={adPath} location={props.location} mappingLeft={Wing} mappingRight={Wing} onSlotRenderEndedLeft={props.onSlotRenderEndedLeft} onSlotRenderEndedRight={props.onSlotRenderEndedRight} />
            <SecondaryNav section={props.section} sectionLabel={props.sectionLabel} relatedSections={props.relatedSections} visitorId={props.visitorId} sessionId={props.sessionId} />
            <Articles
              {...props} noDiv isConnected loading={Skeleton} section={props.section} count={25} pageSize={16} withMore moreButtonColour='btn-red' noImage={NoImage} notFound={NotFound} component={({ articles, section, noImage, moreButton, isConnected, withReadCount }) => (
                <>
                  <x.div w='100%' maxWidth={1220} mx='auto' display='grid' gridTemplateColumns={{ sm: '100%', lg: 'minmax(min-content, 560px) minmax(min-content, 282px) 300px' }} gap={{ lg: '40' }} mt={50} mb={30}>
                    <HomePageTVBox articles={articles.slice(0, 8)} section={section} noImage={noImage} isConnected={isConnected} withReadCount={withReadCount} adPath={adPath} visitorId={props.visitorId} sessionId={props.sessionId} />
                    <x.aside>
                      <MostReadWidget section={props.mostReadSection || (section.split('/')[0])} />
                      <x.div textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column' p={{ sm: '20px', lg: '0' }}>
                        <Ad isConnected={props.isConnected} adPath={adPath} collapseEmptyDiv slotId='MPU-topright' targeting={{ MPU: 'topright' }} className='advert' mapping={mpuRHS} {...FORMATS.mpu} />
                      </x.div>
                    </x.aside>
                  </x.div>
                  <TrendsBar />
                  {/* Membrana start */}
                  {/* {process.env.RAZZLE_ENABLE_MEBRANA && <Membrana />} */}
                  {/* Membrana End */}
                  <x.div my={20} mx={-20} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                    <Ad isConnected={props.isConnected} adPath={adPath} collapseEmptyDiv slotId='Leaderboard-middle' targeting={{ Leaderboard: 'middle' }} className='advert' mapping={Leaderboard} {...FORMATS.leaderboard} />
                  </x.div>
                  <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
                    <SixArticleDeck articles={articles.slice(8, 14)} section={props.section} noImage={noImage} isConnected={isConnected} withReadCount={withReadCount} adPath={adPath} mapping={HP} visitorId={props.visitorId} sessionId={props.sessionId} />
                    <ArticleDeck articles={articles.slice(14, 18)} section={props.section} noImage={noImage} isConnected={isConnected} withReadCount={withReadCount} adPath={adPath} mapping={mpuRHS} visitorId={props.visitorId} sessionId={props.sessionId} />
                    <ThreeArticleDeck articles={articles.slice(18, 21)} section={props.section} noImage={noImage} isConnected={isConnected} withReadCount={withReadCount} adPath={adPath} mapping={mpuRHS} visitorId={props.visitorId} sessionId={props.sessionId} />
                    <ArticleDeck articles={articles.slice(21)} section={props.section} noImage={noImage} moreButton={moreButton} isConnected={isConnected} withReadCount={withReadCount} adPath={adPath} mapping={mpuRHS} visitorId={props.visitorId} sessionId={props.sessionId} />
                  </x.div>
                </>
              )}
            />
            <OtherPublications />
            <x.div mt={20} mb={50} mx={-20} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
              <Ad isConnected={props.isConnected} adPath={adPath} collapseEmptyDiv slotId='Leaderboard-bot' targeting={{ Leaderboard: 'bot' }} className='advert' mapping={Leaderboard} {...FORMATS.leaderboard} />
            </x.div>
          </x.main>
        </>}
    </>
  )
}

SportsBettingPage.defaultProps = {
  component: BasicTemplate,
  section: 'sport/betting'
}
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchArticles, fetchMoreArticles, sectionPageView, setSectionTitle }, dispatch)

export default withRouter(connect((state) => ({ app: state.app, articles: state.articles, visitorId: getVisitorId(state), sessionId: getSessionId(state) }), mapDispatchToProps)(SportsBettingPage))
