import React, { Fragment } from 'react'
import SACovidButton from '../../../assets/images/buttons/SAcoronavirus-button.jpg'
import EmagsButton from '../../../assets/images/buttons/e-mags-button.png'
import { x } from '@xstyled/styled-components'

const EmagsButtonBar = (props) => (
  <x.div display='flex' spaceY={{ sm: '20px', lg: '0' }} p={10} bg='advert' marginTop={50} maxWidth={1220} w='100%' mx='auto' justifyContent='space-between' flexDirection={{ sm: 'column', lg: 'row' }} alignItems={{ sm: 'center', lg: 'none' }}>
    <x.a href={props.newspaperUrl} target='_blank' rel='noopener noreferrer nofollow'><x.img src={props.newspaperButton} title={props.newspaperTitle} alt={props.newspaperTitle} /></x.a>
    <x.a href='https://sacoronavirus.co.za/' target='_blank' rel='noopener noreferrer nofollow'><x.img src={SACovidButton} title='COVID-19 Resource Portal' alt='COVID-19 Resource Portal' /></x.a>
    <x.a href='https://issuu.com/independentnewspapers' target='_blank' rel='noreferrer'><x.img src={EmagsButton} title='E-Mags' alt='E-Mags' /></x.a>
  </x.div>
)

export default EmagsButtonBar
