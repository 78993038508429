import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchOtherPublicationsArticles } from '../../store/otherPublications'
import StyledLink from '../components/ui/Link'
import styled, { x } from '@xstyled/styled-components'
import { getCanonical, getImageSize, getImage, getTeaser } from '../../lib/articleUtils'
import ImageOrNoImage from '../components/ui/Image'
import { formatAgo } from '../../lib/dateUtils'

import { Articles } from '../components/Articles'
import CapeArgusIcon from '../../../assets/images/icons/logo_CA_std.jpg'
import CapeTimesIcon from '../../../assets/images/icons/logo_CT_mix_std.jpg'
import DailyNewsIcon from '../../../assets/images/icons/logo_DN_std.jpg'
import TheMercuryIcon from '../../../assets/images/icons/logo_TM_mix_std.jpg'
import PretoriaNewsIcon from '../../../assets/images/icons/pretoria-news.jpg'
import ThePostIcon from '../../../assets/images/icons/logo_TP_mix_std.jpg'
import TheStarIcon from '../../../assets/images/icons/logo_TS_std.jpg'
import NoImage from '../../../assets/images/general/no-image.png'
import { getSessionId, getVisitorId } from '../../store/article'

const Publications = {
  capeargus: { icon: CapeArgusIcon, url: '/capeargus' },
  capetimes: { icon: CapeTimesIcon, url: '/capetimes' },
  dailynews: { icon: DailyNewsIcon, url: '/dailynews' },
  mercury: { icon: TheMercuryIcon, url: '/mercury' },
  'pretoria-news': { icon: PretoriaNewsIcon, url: '/pretoria-news' },
  thepost: { icon: ThePostIcon, url: '/thepost' },
  'the-star': { icon: TheStarIcon, url: '/the-star' }
}

const OtherPublications = styled.div`
  position: relative;
  .carousel-pagination {
    display: none;
    visibility: hidden;
  }
`

const OtherPublicationArticles = (props) => {
  const clickSource = 'Other Publication'
  if (typeof window === 'undefined') {
    return null
  }
  return (
    <OtherPublications>
      <x.div display='flex' spaceX={10} maxWidth={{ sm: '1260px', wAdD: '1660px' }} w='100%' mx='auto' justifyContent='space-between' mt={50}>
        <x.div borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20} display='flex' w='100%' justifyContent='space-between' flexDirection={{ sm: 'column', lg: 'row' }}>
          <x.h3 color={props.color}>Newspaper Highlights</x.h3>
          <x.div display='flex' gap={5} mt={{ sm: '10px', lg: '0' }}>
            <StyledLink to='/capeargus'><x.img src={CapeArgusIcon} alt='Cape Argus' maxWidth={{ sm: '35px', lg: '50px' }} border='1px solid #DDD' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource} /></StyledLink>
            <StyledLink to='/capetimes'><x.img src={CapeTimesIcon} alt='Cape Times' maxWidth={{ sm: '35px', lg: '50px' }} border='1px solid #DDD' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource} /></StyledLink>
            <StyledLink to='/dailynews'><x.img src={DailyNewsIcon} alt='Daily News' maxWidth={{ sm: '35px', lg: '50px' }} border='1px solid #DDD' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource} /></StyledLink>
            <StyledLink to='/mercury'><x.img src={TheMercuryIcon} alt='The Mercury' maxWidth={{ sm: '35px', lg: '50px' }} border='1px solid #DDD' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource} /></StyledLink>
            <StyledLink to='/pretoria-news'><x.img src={PretoriaNewsIcon} alt='Pretoria News' maxWidth={{ sm: '35px', lg: '50px' }} border='1px solid #DDD' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource} /></StyledLink>
            <StyledLink to='/thepost'><x.img src={ThePostIcon} alt='The Post' maxWidth={{ sm: '35px', lg: '50px' }} border='1px solid #DDD' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource} /></StyledLink>
            <StyledLink to='/the-star'><x.img src={TheStarIcon} alt='The Star' maxWidth={{ sm: '35px', lg: '50px' }} border='1px solid #DDD' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource} /></StyledLink>
          </x.div>
        </x.div>
      </x.div>

      <x.div w='100%' maxWidth={{ sm: '1260px', wAdD: '1660px' }} mx='auto' mb={50} mt={20} position='relative' display='grid' gridTemplateColumns={{ sm: 'repeat(12, 100%)', lg: 'repeat(12, calc((100% - 60px) / 4))' }} gap={20} overflowX='auto'>
        <Articles
          section='newspapertitles' page0Limit={12} pageSize={12} fetchArticles={props.fetchOtherPublicationsArticles}
          noImage={NoImage}
          notFound={() => (<div>Oops!</div>)}
          fetchMoreArticles={() => { }}
          articles={props.articles}
          filter={[...Object.keys(Publications)]}
          noDiv
          component={({ articles }) => (
            <>
              {articles.map((article, index) => (
                <x.article key={index} position='relative' p='0 0 20px 0' borderWidth={{ sm: '0 0 0px 0', lg: '0', lastType: '0' }} mb={{ sm: '20px', lg: '0', last: '0' }}>
                  <StyledLink article={article} to={'/' + getCanonical(article)} display='flex' alignItems='flex-start' spaceX={{ sm: '10px', lg: 'unset' }} flexDirection={{ sm: 'row', lg: 'column-reverse' }} justifyContent={{ sm: 'space-between', lg: 'unset' }} visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource} color={props.color} hoverColor={props.hoverColor}>
                    <x.div mt={{ lg: '10px' }}>
                      <x.h5 text={{ sm: 'h6', lg: 'h5' }} mb={8}>{getTeaser(article)}</x.h5>
                      <x.p text='meta' color={props.color || 'meta'} my={8}>By {article.author} | Published {formatAgo(article.published, true)}</x.p>
                    </x.div>
                    <x.div position='relative' display='flex' justifyContent='center' alignItems='center'>
                      <ImageOrNoImage image={getImage(article)} w='auto' width={getImageSize('slider')} alt={getTeaser(article)} shape='16x9' display={{ sm: 'none', lg: 'block' }} visibility={{ sm: 'hidden', lg: 'visible' }} noImage={NoImage} />
                      <ImageOrNoImage image={getImage(article)} maxWidth={50} width={getImageSize('slider')} alt={getTeaser(article)} shape='square' display={{ sm: 'block', lg: 'none' }} visibility={{ sm: 'visible', lg: 'hidden' }} noImage={NoImage} />
                    </x.div>
                  </StyledLink>
                </x.article>
              ))}
            </>
          )}
        />
      </x.div>
    </OtherPublications>
  )
}

OtherPublicationArticles.propTypes = {
  articles: PropTypes.object.isRequired,
  fetchOtherPublicationsArticles: PropTypes.func.isRequired
}

OtherPublicationArticles.defaultProps = {
  pageSize: 4
}

const mapStateToProps = (state) => ({ articles: state.otherPublications, visitorId: getVisitorId(state), sessionId: getSessionId(state) })
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchOtherPublicationsArticles }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(OtherPublicationArticles)
