import React from 'react'
import styled, { x } from '@xstyled/styled-components'
import Link from './ui/Link'
import { Carousel, useCarousel } from './ui/Carousel'

import next from '../../../assets/images/icons/gallery-next.svg'
import prev from '../../../assets/images/icons/gallery-prev.svg'

import lee from '../../../assets/images/opinion-authors/lee.webp'
import bayetheMsimang from '../../../assets/images/opinion-authors/bayethe-msimang.webp'
import biancaVanWyk from '../../../assets/images/opinion-authors/bianca-van-wyk.webp'
import donovanWilliams from '../../../assets/images/opinion-authors/donovan-williams.webp'
import lanceWitten from '../../../assets/images/opinion-authors/lance-witten.webp'
import nalediNggambela from '../../../assets/images/opinion-authors/naledi-nggambela.webp'
import phakamileHlubiMajola from '../../../assets/images/opinion-authors/phakamile-hlubi-majola.webp'
import roscoePalm from '../../../assets/images/opinion-authors/roscoe-palm.webp'
import ayandaSishiWigzell from '../../../assets/images/opinion-authors/ayanda-sishi-wigzell.webp'
import gillianSchutte from '../../../assets/images/opinion-authors/gillian-schutte.webp'

const Slider = styled.div`
  position: relative;
  .carousel-pagination {
    display: none;
    visibility: hidden;
  }
  a, img {
    display: block;
    margin: auto;
  }
`
const OpinionColumnistSlider = ({ visitorId, sessionId, section }) => {
  const clickSource = 'Opinion Columnist Slider'
  const carousel = useCarousel({
    slidesToShow: 3,
    slidesToSwipe: 2,
    mobileSlidesToShow: 3,
    mobileSlidesToSwipe: 2
  })
  const authors = [lanceWitten, lee, biancaVanWyk, donovanWilliams, phakamileHlubiMajola, roscoePalm, gillianSchutte, ayandaSishiWigzell, nalediNggambela, bayetheMsimang]
  const authorLinks = ['/authors/lance-witten', '/authors/lee-rondganger', '/authors/bianca-van-wyk', '/authors/donovan-williams', '/authors/phakamile-hlubi-majola', '/authors/roscoe-palm', 'authors/gillian-schutte', '/authors/opinion', '/authors/opinion', '/authors/opinion']

  return (
    <Slider>
      <x.div w='100%' mx='auto' my={{ sm: '20px', lg: '50px' }} mb={10} px={{ sm: 20, lg: 50 }} position='relative'>
        <x.button mr='xs' onClick={carousel.goPrev} background='transparent' position='absolute' left={{ sm: '-20px', lg: 0 }} top='50%' transform='translateY(-50%)' opacity={{ disabled: '.5' }}>
          <x.img w={{ sm: '50%', lg: '100%' }} src={prev} />
        </x.button>
        <x.button onClick={carousel.goNext} background='transparent' position='absolute' right={{ sm: '-20px', lg: 0 }} top='50%' transform='translateY(-50%)' opacity={{ disabled: '.5' }}>
          <x.img w={{ sm: '50%', lg: '100%' }} src={next} />
        </x.button>
        <Carousel {...carousel} display='flex' alignItems='center' gap={20}>
          {authors.map((author, index) => {
            return (
              <Carousel.Slide
                key={index} alignItems='center' display='flex' alignSelf='center' p={{ sm: '10px 10px', lg: '20px 20px' }}
                css={{
                  '&:hover': {
                    transform: 'translateY(-10px)'
                  }
                }}
              >
                <Link to={authorLinks[index]} visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource}>
                  <x.img src={author} />
                </Link>
              </Carousel.Slide>
            )
          })}
        </Carousel>
      </x.div>
    </Slider>
  )
}

export default OpinionColumnistSlider
