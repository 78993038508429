import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { x } from '@xstyled/styled-components'
import { fetchStaticPage } from '../../store/staticPage'

export const Skeleton = (props) => {
  return (
    <x.div my={-20} mx='auto' maxWidth={1220} w='100%'>
      <x.div h={3} w={80} bg='#F0F0F0'></x.div>
      <x.div h={3} w={80} bg='#F0F0F0'></x.div>
      <x.div h={3} w={80} bg='#F0F0F0'></x.div>
      <x.div h={3} w={80} bg='#F0F0F0'></x.div>
    </x.div>
  )
}



export class NewspaperTitleLinks extends Component {
  constructor (props) {
    super(props)
    if (!this.props.hasFetched || this.props.pageName !== 'newspapers-community-news/newspapers' ) {
      props.fetchStaticPage('newspapers-community-news/newspapers')
    }
  }

  getTitle (titleName) {
    if (this.props.staticPage && this.props.staticPage.newspapers) {
      return this.props.staticPage.newspapers.filter(title => title.newspaperTitle === titleName)
    }
    console.error('NewspaperTitleLinks: newspapers object unavaile', this.props.staticPage)
    return undefined
  }

  render() {
    if (this.props.hasError) {
      return (
        <p>Error: {this.props.error}</p>
      )
    }
    if (this.props.hasFetched && this.props.staticPage && this.props.pageName === 'newspapers-community-news/newspapers') {
      let page = this.props.staticPage
      const title = this.getTitle(this.props.titleName)[0] || {}
      return (
        <x.div mt={{sm: '20px', lg: '-20px'}} mb={{ sm: '-20px', lg: '-20px' }} mx='auto' maxWidth={1220} w='100%' display='flex' flexWrap='wrap' gap={10}>
          {title.aboutLink && (
            <x.a href={title.aboutLink} target='_blank' text="small" color={{ _: 'red', hover: 'red' }} backgroundColor={{ _: 'redAlpha', hover: 'rgba(180, 20, 0, 0.2)' }} p='6px 12px' textTransform='uppercase' borderRadius='100' whiteSpace='nowrap'>About</x.a>
          )}
          {title.contactLink && (
            <x.a href={title.contactLink} target='_blank' text="small" color={{ _: 'red', hover: 'red' }} backgroundColor={{ _: 'redAlpha', hover: 'rgba(180, 20, 0, 0.2)' }} p='6px 12px' textTransform='uppercase' borderRadius='100' whiteSpace='nowrap'>Contact {this.props.titleName}</x.a>
          )}
          {title.advertisingLink && (
            <x.a href={title.advertisingLink} target='_blank' text="small" color={{ _: 'red', hover: 'red' }} backgroundColor={{ _: 'orangeAlpha', hover: 'rgba(180, 20, 0, 0.2)' }} p='6px 12px' textTransform='uppercase' borderRadius='100' whiteSpace='nowrap'>Advertise</x.a>
          )}
          {title.eEditionSubscribeLink && (
            <x.a href={title.eEditionSubscribeLink} target='_blank' text="small" color={{ _: 'red', hover: 'red' }} backgroundColor={{ _: 'redAlpha', hover: 'rgba(180, 20, 0, 0.2)' }} p='6px 12px' textTransform='uppercase' borderRadius='100' whiteSpace='nowrap'>Subscribe</x.a>
          )}
        </x.div>
      )
    } else {
      return (
        <Skeleton />
      )
    }
  }
}

NewspaperTitleLinks.defaultProps = {
  titleName: 'Cape Argus'
}

const mapStateToProps = (state) => (state.staticPage)
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchStaticPage }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NewspaperTitleLinks)
