import React from 'react'
// import React, { Fragment } from 'react'
// import ArticleCard from '../../app/components/ArticleCard'
// import SectionPageHighlight from './SectionPageHighlight'
// import Ad from '../components/Ad'
// import FORMATS from '../../app/components/AdFormats'
// import TrendsBar from '../../app/components/TrendsBar'

const Default = ({ articles, authorName, section, noImage, isConnected, sectionLabel, moreButton, relatedSections, withReadCount, adPath }) => {
  return (
    <div>Section Page Highlight goes here....</div>)
}

export default Default
