import React from 'react'
import { x } from '@xstyled/styled-components'
import StyledLink from './ui/Link'
import cssfxUnderline from './ui/cssfxUnderline'
import { getSectionLabel } from 'ion-sections'

const SecondaryNav = ({ section, sectionLabel, relatedSections, visitorId, sessionId }) => {
  const clickSource = 'Secondary Nav'
  return (
    <x.div w='100%' maxWidth={1220} mx='auto' my={50} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' visibility={{ sm: 'hidden', lg: 'visible' }} display={{ sm: 'none', lg: 'grid' }} gridTemplateColumns={{ sm: '100%', lg: 'min-content auto' }} gap={{ lg: '40' }} alignItems='baseline' flexWrap='wrap' position='relative' zIndex='1'>
      <x.h1 text='h4' fontFamily='"PT Serif", serif' pb={20} fontWeight='regular' whiteSpace='nowrap'>{sectionLabel || getSectionLabel(section)}</x.h1>
      <x.nav display='flex' flexDirection='row' gap={20} aria-label="Section Navigation" pb={{ sm: '20', article: '0' }} overflowX='auto'>
        {relatedSections && relatedSections.map(relatedSection => (
          <StyledLink key={relatedSection.id} to={'/' + relatedSection.id} text='small' section={section} visitorId={visitorId} sessionId={sessionId} source={clickSource}>
            <cssfxUnderline.SweepIn hoverBGColor='orange' hoverColor='orange'>{relatedSection.label || getSectionLabel(relatedSection.id)}</cssfxUnderline.SweepIn>
          </StyledLink>
          ))}
      </x.nav>
    </x.div>
  )
}

export default SecondaryNav
