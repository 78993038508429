import React from 'react'
import { x } from '@xstyled/styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchEditorsChoiceArticles } from '../../store/editorsChoice'
import { getSessionId, getVisitorId } from '../../store/article'

import { getCanonical, getTeaser } from '../../lib/articleUtils'
import StyledLink from './ui/Link'
import { Articles } from 'ion-article-cmp'
import NoImage from '../../../assets/images/general/no-image.png'

export const Skeleton = (props) => (
  Array(5).fill().map((v, i) =>
    <x.article key={i} pb={{ _: '20px', lastType: '0' }} mb={{ _: '20px', lastType: '0' }} borderWidth={{ _: '0 0 1px 0', lastType: '0' }} borderStyle='solid' borderColor='divider'>
      <x.div h={5} mb={5} bg='#F0F0F0' />hello
      <x.div w='33%' h={5} bg='#F0F0F0' />
    </x.article>
  )
)

const OpinionEditorsChoiceArticles = (props) => {
  const clickSource = 'Editors Choice'
  return (
    <x.div px={15} py={8} mb={20} display='flex' justifyContent='space-between' flexWrap='nowrap'>
      <Articles
        className={props.className} loading={Skeleton} section='editorschoice' page0Limit={5} pageSize={5} fetchArticles={props.fetchEditorsChoiceArticles}
        noImage={NoImage} notFound={() => (<Skeleton />)} fetchMoreArticles={() => { }} articles={props.articles}
        component={({ articles }) => {
          return articles.map((article, index) => (
            <x.article article={article} key={article.uuid + '-' + index} pb={{ _: '20px', lastType: '0' }} mb={{ _: '20px', lastType: '0' }} borderWidth={{ _: '0 0 1px 0', lastType: '0' }} borderStyle='solid' borderColor='divider'>
              <StyledLink to={'/' + getCanonical(article)} color='white' hoverColor='meta' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>
                <x.h6 text='h6'>{getTeaser(article)}</x.h6>
              </StyledLink>
            </x.article>
          ))
        }}
      />
    </x.div>
  )
}

OpinionEditorsChoiceArticles.propTypes = {
  articles: PropTypes.object.isRequired,
  fetchEditorsChoiceArticles: PropTypes.func.isRequired
}

OpinionEditorsChoiceArticles.defaultProps = {
  pageSize: 5
}

const mapStateToProps = (state) => ({ articles: state.editorsChoice, visitorId: getVisitorId(state), sessionId: getSessionId(state) })
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchEditorsChoiceArticles }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(OpinionEditorsChoiceArticles)
