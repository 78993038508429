import React from 'react'
import { getCanonical, getImageSize, getImage, getTeaser } from '../lib/articleUtils'
import { x } from '@xstyled/styled-components'
import { formatAgo } from '../lib/dateUtils'
import ImageOrNoImage from '../app/components/ui/Image'
import NoImage from '../../assets/images/general/no-image.png'
import StyledLink from '../app/components/ui/Link'
import Ad from '../app/components/Ad'
import { FORMATS } from '../app/components/AdFormats'

const OpinionArticles = ({ article, hoverColor, visitorId, sessionId, section, clickSource, color }) => {
  return (
    <StyledLink article={article} to={'/' + getCanonical(article)} display='flex' alignItems='flex-start' spaceX={{ sm: '10px', lg: 'unset' }} flexDirection={{ sm: 'row', lg: 'column' }} justifyContent={{ sm: 'space-between', lg: 'unset' }} visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource} color={color} hoverColor={hoverColor}>
      <x.div position='relative' display='flex' justifyContent='center' alignItems='center'>
        <ImageOrNoImage image={getImage(article)} w='auto' width={getImageSize('slider')} alt={getTeaser(article)} shape='16x9' display={{ sm: 'none', lg: 'block' }} visibility={{ sm: 'hidden', lg: 'visible' }} noImage={NoImage} />
        <ImageOrNoImage image={getImage(article)} maxWidth={100} width={getImageSize('slider')} alt={getTeaser(article)} shape='square' display={{ sm: 'block', lg: 'none' }} visibility={{ sm: 'visible', lg: 'hidden' }} noImage={NoImage} />
      </x.div>
      <x.div mt={{ lg: '10px' }}>
        <x.h5 text={{ sm: 'h6', lg: 'h5' }} mb={8}>{getTeaser(article)}</x.h5>
        <x.p text='meta' color='meta' my={8}>By {article.author} | Published {formatAgo(article.published, true)}</x.p>
      </x.div>
    </StyledLink>
  )
}

const Template = ({ articles, authorName, section, noImage, isConnected, sectionLabel, relatedSections, withReadCount, adPath, mapping, visitorId, sessionId, color, hoverColor, isOpinion }) => {
  const clickSource = 'Three Article Deck'
  return (
    <x.div display={{ sm: '', lg: 'grid' }} gridTemplateColumns={{ sm: '', lg: 'calc(100% - 340px) 300px' }} gap={{ lg: '40' }} w='100%' mb={40} mt={40}>
      <x.div display={{ sm: '', lg: 'grid' }} gridTemplateColumns={{ sm: '', lg: 'repeat(3, calc((100% - 80px) / 3))' }} gap={{ lg: '40' }}>
        {articles.map((article, index) => (
          <x.article key={article.uuid} position='relative' className='divider-right-six' p={{ sm: '0 0 20px 0', lg: '0', lastType: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0', lastType: '0' }} borderStyle='solid' borderColor='divider' mb={{ sm: '20px', lg: '0', last: '0' }}>
            {!isOpinion &&
              <StyledLink article={article} to={'/' + getCanonical(article)} display='flex' alignItems='flex-start' spaceX={{ sm: '10px', lg: 'unset' }} flexDirection={{ sm: 'row', lg: 'column-reverse' }} justifyContent={{ sm: 'space-between', lg: 'unset' }} visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource} color={color} hoverColor={hoverColor}>
                <x.div mt={{ lg: '10px' }}>
                  <x.h5 text={{ sm: 'h6', lg: 'h5' }} mb={8}>{getTeaser(article)}</x.h5>
                  <x.p text='meta' color='meta' my={8}>By {article.author} | Published {formatAgo(article.published, true)}</x.p>
                </x.div>
                <x.div position='relative' display='flex' justifyContent='center' alignItems='center'>
                  <ImageOrNoImage image={getImage(article)} w='auto' width={getImageSize('slider')} alt={getTeaser(article)} shape='16x9' display={{ sm: 'none', lg: 'block' }} visibility={{ sm: 'hidden', lg: 'visible' }} noImage={NoImage} />
                  <ImageOrNoImage image={getImage(article)} maxWidth={50} width={getImageSize('slider')} alt={getTeaser(article)} shape='square' display={{ sm: 'block', lg: 'none' }} visibility={{ sm: 'visible', lg: 'hidden' }} noImage={NoImage} />
                </x.div>
              </StyledLink>}
            {isOpinion &&
              <OpinionArticles
                article={article} hoverColor={hoverColor} visitorId={visitorId} sessionId={sessionId} section={section} clickSource={clickSource} color={color}
              />}
          </x.article>
        ))}
      </x.div>
      <x.div textAlign='center' display='flex' justifyContent='flex-start' alignItems='center' flexDirection='column' p={{ sm: '20px', lg: '0' }} mt={{ sm: '40px', lg: '0' }}>
        <Ad isConnected={isConnected} adPath={adPath} slotId='MPU-botright' targeting={{ MPU: 'botright' }} collapseEmptyDiv className='advert' mapping={mapping} {...FORMATS.mpu} />
      </x.div>
    </x.div>

  )
}

const ThreeArticleDeck = (props) => {
  return (
    <Template {...props} />
  )
}

export default ThreeArticleDeck
